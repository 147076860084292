<template>
	<div>
		<Nav @link="linkTo" :idx="0"></Nav>
		<div class="tabbox">
			<div class="tab_box">
				<div :class="['tab_item', {'active_bg': 0 === i}]" v-for="(item, i) in tabList" :key="i" @click="tapClick(i)">{{item.label}}</div>
			</div>
			<div class="tab_linkage content">
				<ul>
					<li v-for="(item, i) in tabList" :key="i">
						<img class="tab_linkage_img" :src="item.src" alt="">
						<div class="">
							<div class="tab_linkage_title">{{item.label}}</div>
							<div class="tab_linkage_con">{{item.content}}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- 我们的团队 -->
		<div>
			<div class="my_team">我们的团队</div>
			<div class="line_block"></div>
			<div class="team_container">
				<div class="bg_team pc_hidden">
					<img class="bg_team_img" :src="require('@/assets/img/team.png')" alt="">
				</div>
				<div class="team_list">
					<div v-for="(item, i) in teamList" :key="i" :class="['team_item', {hover: i === mouseIdx}]" @mouseover="mouseOver(i)">
						<div class="circle"></div>
						<div class="inline_con">
							<div class="team_title">{{item.title}}</div>
							<div class="team_con">{{item.desc}}</div>
						</div>
					</div>
				</div>
				<div class="bg_team mobile_hidden">
					<img class="bg_team_img" :src="require('@/assets/img/team.png')" alt="">
				</div>
			</div>
			<div class="team_desc">我们拥有最全面最专业服务，覆盖杭州市下城区、拱墅区、滨江区、江干区、钱塘新区。</div>
		</div>
		<!-- 我们的成绩 -->
		<div class="achievement">
			<div class="achievement_title">我们的成绩</div>
			<div class="line_block line_block_achievement"></div>
			<div class="achievement_box">
				<div class="achievement_item" v-for="(item, i) in achieveList" :key="i">
					<div class="item_list">
						<div class="item_label">{{item.label}}</div>
						<div class="item_desc">{{item.desc}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 战略合作伙伴 -->
		<div>
			<div class="my_team">战略合作伙伴</div>
			<div class="line_block"></div>
			<div class="partner">
				<div class="partner_box_img" v-for="(item, i) in imgList" :key="i">
					<img class="partner_img" :src="item" alt="">
				</div>
				<swiper class="mobile_swiper" :options="swiperOption">
					<swiper-slide v-for="(slide, i) in swiperSlides" :key="i">
						<img v-for="(item, j) in slide" :key="j" class="partner_img" :src="item" alt="">
					</swiper-slide>
					<!-- Add Pagination -->
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		<!-- 合作伙伴 -->
		<div>
			<div class="my_team">合作伙伴</div>
			<div class="line_block"></div>
			<div class="partner">
				<div class="partner_box_img" v-for="(item, i) in imgList2" :key="i">
					<img class="partner_img" :src="item" alt="">
				</div>
				<swiper class="mobile_swiper" :options="swiperOption">
					<swiper-slide v-for="(slide, i) in swiperSlides2" :key="i">
						<img v-for="(item, j) in slide" :key="j" class="partner_img" :src="item" alt="">
					</swiper-slide>
					<!-- Add Pagination -->
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import $ from 'jquery'

export default {
	name: 'index',
	components: {
		Nav,
		Footer
	},
	data () {
		return {
			mouseIdx: 0,
			teamList: [
				{title: '技术团队', desc: '7*24小时服务，为使用者提供技术支持、系统维护，新功能开发等服务。'},
				{title: '运营团队', desc: '通过躬身亲为的实践成果，为老人、服务商、政府单位提供优质养老服务运营解决方案。'},
				{title: '养老顾问', desc: '通过跟老人的线上线下沟通，为老年人提供咨询、服务定制等，让老人的生活所需得到更好的服务。'},
				{title: '助老员', desc: '经过助老服务方面的技能和态度等全方位培训，为老人提供上门服务，老人在家中即可享受到专业照料、看护支持等服务。'},
				{title: '医疗团队', desc: '提供专业医疗服务，更好的安全保障老人的身体健康。'},
			],
			tabList: [{
					label: '智慧养老',
					src: require('@/assets/img/index/yl.png'), 
					content: '智慧养老平台涵盖居家养老、机构养老、社区养老、商家服务、和政府监管等模块。包括智慧养老运作模式，智慧养老产品，智慧化养老标准。'
				},{
					label: '智慧社区',
					src: require('@/assets/img/index/sq.png'), 
					content: '为政府、居民、企业提供全方位解决方案，集基层治理、社区服务于一体，打造便捷安全的智慧社区综合体，提升居民幸福感、获得感和安全感。'
				},{
					label: '智慧物联',
					src: require('@/assets/img/index/wl.png'), 
					content: '基于云架构的智慧物联体系，可对集成传感器、智能终端，形成强大的边缘计算体系，并整合各类网络连接策略从而实现统一的移动互联，通过接各类业务应用系统汇聚成智慧物联大数据，从而形成万物互联和的综合业务管控解决方案。'
				}
			],
			achieveList: [
				{label: '15家', desc: '服务设施机构数'},
				{label: '40万+', desc: '服务老人总人数'},
				{label: '13万+', desc: '签约老人人数'},
				{label: '16万+', desc: '咨询总量'},
				{label: '8万+', desc: '累计服务人次'},
				{label: '4.9分', desc: '服务水平分数'},
				{label: '5个区', desc: '杭州服务范围'},
				{label: '24小时', desc: '每天服务时间'},
			],
			imgList2: [
				require('@/assets/img/partner/10.png'),
				require('@/assets/img/partner/7.png'),
				require('@/assets/img/partner/8.png'),
				require('@/assets/img/partner/18.png'),
				require('@/assets/img/partner/9.png'),
				require('@/assets/img/partner/17.png'),
				require('@/assets/img/partner/11.png'),
				require('@/assets/img/partner/12.png'),
				require('@/assets/img/partner/16.png'),
				require('@/assets/img/partner/14.png')
			],
			imgList: [
				require('@/assets/img/partner/1.png'),
				require('@/assets/img/partner/2.png'),
				require('@/assets/img/partner/3.png'),
				require('@/assets/img/partner/4.png'),
				require('@/assets/img/partner/5.png'),
				require('@/assets/img/partner/20.png'),
				require('@/assets/img/partner/6.png'),
				require('@/assets/img/partner/15.png'),
				require('@/assets/img/partner/19.png'),
				require('@/assets/img/partner/13.png')
			],
			swiperOption: {	// swiper 配置项
				loop : true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.swiper-pagination'
				},
			},
			swiperSlides: null,	// 移动端合作伙伴轮播
			swiperSlides2: null,	// 移动端合作伙伴轮播
			screenWidth: document.body.clientWidth	// 屏幕宽度
		}
	},
	mounted () {
		window.scrollTo(0, 0);
		// 计算 屏幕宽度 和 ul 的长度
		if (this.screenWidth <= 767) {
			$('.tabbox .content ul').width(340*$('.tabbox .content li').length+'px');
		} else if (this.screenWidth >= 992) {
			$('.tabbox .content ul').width(1200*$('.tabbox .content li').length+'px');
		}
		// 轮播slider
		let pages = [], pages2 = []
		this.imgList.forEach((item, i) => {
			let page = Math.floor(i / 4)
			if (!pages[page]) 
				pages[page] = []
			pages[page].push(item)
		})
		this.imgList2.forEach((item, i) => {
			let page = Math.floor(i / 4)
			if (!pages2[page]) 
				pages2[page] = []
			pages2[page].push(item)
		})
		this.swiperSlides = pages
		this.swiperSlides2 = pages2
	},
	methods: {
		mouseOver (i) {
			this.mouseIdx = i
		},
		tapClick (i) {
			$('.tab_box .tab_item').eq(i).addClass('active_bg').siblings().removeClass('active_bg');
			let distance = 0;
			if (this.screenWidth <= 767) {
				distance = -340 * i;	// 340是每个li的宽度
			} else if (this.screenWidth >= 992) {
				distance = -1200 * i;
			}
			$('.tabbox .content ul').stop().animate({
				left: distance
			});
		},
		linkTo (routerName) {
			this.$router.push(routerName)
		},
	}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {	// 移动端
	.mobile_hidden {
		display: none;
	}
	.tabbox {
		width: 340px;
		height: 430px;
		margin: auto;
		.tab_box {
			display: flex;
			box-sizing: border-box;
			margin: 10px auto;
			background: #F0F4F7;
			padding: 5px;
			border-radius: 3px;
			.active_bg {
				background: #FFFFFF;
			}
			.tab_item {
				width: 25%;
				text-align: center;
				border-radius: 3px;
				padding: 5px 0;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 500;
				color: #585858;
			}
		}
		.tab_linkage {
			width: 340px;
			height: 390px;
			overflow: hidden;
			position: relative;
			ul {
				margin: 0;
				padding: 0px;
				position: absolute;
				left: 0;
				top: 0;
				li {
					width: 340px;
					height: 390px;
					float: left;
					list-style: none;
					padding: 0px;
					box-sizing: border-box;
				}
			}
			.tab_linkage_img {
				width: 100%;
			}
			.tab_linkage_title {
				margin: 20px 0 10px;
				text-align: center;
				font-size: 19px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #2890FC;
			}
			.tab_linkage_con {
				flex: 1;
				line-height: 1.5;
				min-width: 0;
				letter-spacing: 1px;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #666666;
			}
		}
	}
	.my_team {
		margin-top: 30px;
		text-align: center;
		font-size: 25px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}
	.line_block {
		margin: 20px auto 10px;
		width: 25px;
		height: 1px;
		background: #2890FC;
	}
	.team_container {
		padding: 0 10px;
		.team_list {
			padding-top: 20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.team_item {
				width: 43%;
				margin: 3px 0;
				padding: 10px;
				background: #FFFFFF;
				border-radius: 5px;
				display: flex;
				box-shadow: 0px 0px 9px 0px rgba(217, 217, 217, 0.5);
				.circle {
					width: 7px;
					height: 7px;
					margin-top: 2px;
					border-radius: 50%;
					border: 1px solid #196FF9;
				}
				.inline_con {
					flex: 1;
					margin-left: 4px;
					.team_title {
						font-size: 14px;
						font-family: SourceHanSansCN-Medium, SourceHanSansCN;
						font-weight: 500;
						color: #333333;
					}
					.team_con {
						margin-top: 3px;
						font-size: 13px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #676767;
					}
				}
			}
		}
		.bg_team {
			text-align: center;
			margin-top: 10px;
			.bg_team_img {
				width: 100%;
			}
		}
	}
	.team_desc {
		padding: 15px;
		line-height: 1.5;
		text-align: center;
		font-size: 13px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
	}
	.achievement {
		width: 100%;
		background: url('~@/assets/img/achievement2.png') no-repeat;
		background-size: 100% 100%;
		.achievement_title {
			font-size: 25px;
			padding: 22px 0 0;
			text-align: center;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #FFFFFF;
		}
		.line_block_achievement {
			background: #fff;
		}
		.achievement_box {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			.achievement_item {
				width: 33.3%;
				margin: 16px 0 0;
				.item_list {
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 101px;
					height: 113px;
					background: url('~@/assets/img/hexagon.png') no-repeat;
					background-size: 100% 100%;
					.item_label {
						margin-left: -8px;
						font-size: 17px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #FABC64;
					}
					.item_desc {
						width: 84px;
						text-align: center;
						margin-top: 6px;
						margin-left: -8px;
						font-size: 12px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}
	.partner {
		padding: 10px;
		.partner_box_img {
			display: none;
		}
		.mobile_swiper {
			.partner_img {
				width: 48%;
				margin: 0 1%;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {	// iPad
	
}

@media screen and (min-width: 992px) {	// PC端
	.pc_hidden {
		display: none;
	}
	.tabbox {
		width: 1200px;
		height: 600px;
		margin: auto;
		.tab_box {
			width: max-content;
			display: flex;
			margin: 80px auto 70px;
			background: #F0F4F7;
			padding: 5px;
			.active_bg {
				background: #FFFFFF;
			}
			.tab_item {
				cursor: pointer;
				border-radius: 3px;
				padding: 10px 40px;
				font-size: 24px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #7D8B86;
			}
		}
		.tab_linkage {
			width: 1200px;
			height: 600px;
			overflow: hidden;
			position: relative;
			ul {
				padding: 0px;
				position: absolute;
				left: 0;
				top: 0;
				li {
					width: 1200px;
					height: 430px;
					float: left;
					list-style: none;
					padding: 0px 20px;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
				}
			}
			.tab_linkage_img {
				width: 590px;
				height: 430px;
				box-shadow: 4px 6px 11px 0px rgba(0, 0, 0, 0.13);
			}
			.tab_linkage_title {
				margin-left: 80px;
				font-size: 24px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #2890FC;
			}
			.tab_linkage_con {
				line-height: 1.5;
				margin-left: 80px;
				margin-top: 13px;
				letter-spacing: 2px;
				font-size: 20px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #666666;
			}
		}
	}
	.my_team {
		margin: 70px 0 33px;
		text-align: center;
		font-size: 34px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}
	.line_block {
		margin: 0 auto;
		width: 50px;
		height: 2px;
		background: #2890FC;
	}
	.team_container {
		display: flex;
		justify-content: center;
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 15px;
		.team_list {
			max-width: 650px;
			padding-top: 83px;
			display: flex;
			flex-wrap: wrap;
			.hover {
				box-shadow: 0px 0px 17px 0px rgba(217, 217, 217, 0.5);
			}
			.team_item {
				margin-right: 14px;
				height: max-content;
				padding: 20px;
				background: #FFFFFF;
				border-radius: 10px;
				display: flex;
				.circle {
					width: 9px;
					height: 9px;
					margin-top: 4px;
					border-radius: 50%;
					border: 2px solid #196FF9;
				}
				.inline_con {
					margin-left: 4px;
					.team_title {
						font-size: 20px;
						font-family: SourceHanSansCN-Medium, SourceHanSansCN;
						font-weight: 500;
						color: #333333;
					}
					.team_con {
						width: 192px;
						letter-spacing: 1px;
						line-height: 1.5;
						margin-top: 5px;
						font-size: 16px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #676767;
					}
				}
			}
		}
		.bg_team {
			width: 631px;
			height: 458px;
			text-align: center;
			.bg_team_img {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%; 
			}
		}
	}
	.team_desc {
		width: 600px;
		letter-spacing: 1px;
		line-height: 1.5;
		margin: 80px auto;
		text-align: center;
		font-size: 16px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #3D3D3D;
	}
	.achievement {
		width: 100%;
		background: url('~@/assets/img/achievement.png') no-repeat;
		background-size: 100% 100%;
		.achievement_title {
			font-size: 34px;
			padding: 59px 0 34px;
			text-align: center;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #FFFFFF;
		}
		.line_block_achievement {
			background: #fff;
		}
		.achievement_box {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			.achievement_item {
				width: 25%;
				margin: 25px 0;
				.item_list {
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 192px;
					height: 216px;
					background: url('~@/assets/img/hexagon.png') no-repeat;
					background-size: 100% 100%;
					.item_label {
						margin-left: -13px;
						font-size: 32px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #FABC64;
					}
					.item_desc {
						margin-top: 17px;
						margin-left: -13px;
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}
	.partner {
		width: 100%;
		max-width: 1200px;
		margin: 35px auto 80px;
		display: flex;
		flex-wrap: wrap;
		.mobile_swiper {
			display: none;
		}
		.partner_box_img {
			width: 20%;
			height: 74px;
			text-align: center;
			margin-bottom: 30px;
			.partner_img {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
</style>